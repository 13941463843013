import React, { useState } from 'react'
import '../CommonDropDown/CommonDropDown.scss'
import '../../../Utils/Styles/Colors.css';
import { FaInfoCircle } from "react-icons/fa";
import { LuLoader2 } from "react-icons/lu";

const CommonDropDown = ({ selectedValue, handleSelectChange, options = [], extraStyles, showDefaultLabel, error, disabled, displayLoader, applyCustomStyles, }) => {

    let dropdownStyles = {


    };

    if (error) {
        dropdownStyles['borderColor'] = 'var(--primary-error-color)';
    }


    const [displayError, setDisplayError] = useState(false);//make it false



    return (
        <div className={`common-dropdown-container`}>
            <select
                value={selectedValue}
                onChange={handleSelectChange}
                //style={dropdownStyles}
                style={extraStyles}
                disabled={disabled}
                className={applyCustomStyles ? 'custom-styled-select' : "default-class"}
            >
                {showDefaultLabel && <option value="" disabled hidden>State</option>}
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
            {
                error ?
                    <div
                        onMouseEnter={(e) => {
                            setDisplayError(true);
                        }}
                        onMouseLeave={(e) => {
                            setDisplayError(false);
                        }}
                        className={`input-error-container`}>
                        <FaInfoCircle />
                    </div>
                    : null
            }
            {
                error && displayError ?
                    <div
                        className={`input-error-text-container input-right-icon-added`}>
                        <p>
                            {error}
                        </p>
                    </div>
                    : null
            }
            {
                displayLoader ?
                    <LuLoader2 className={`dropdown-spinner`} />
                    : null
            }
        </div >
    );
};

export default CommonDropDown