import React, { useEffect, useState } from 'react'
import '../Subscription/SubscriptionPageStyle.scss'
import PostLoginHeader from '../../Components/Common/PostLoginHeader';
import { useNavigate } from 'react-router-dom';
import blue_square from '../../Assets/Images/blue_square.png'
import blue_circle from '../../Assets/Images/blue_circle.png'
import blue_hexagon from '../../Assets/Images/blue_hexagon.png'
import blue_gear from '../../Assets/Images/blue_gear.png'
import green_circle from '../../Assets/Images/green_circle.png'
import green_gear from '../../Assets/Images/green_gear.png'
import green_hexagon from '../../Assets/Images/green_hexagon.png'
import green_rectangle from '../../Assets/Images/green_rectangle.png'
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';



const SubscriptionPage = () => {

    const navigate = useNavigate();

    useEffect(() => {
        document.title = 'Subscription';
    }, []);

    const [isChecked, setIsChecked] = useState(false);

    const handleToggle = () => {
        setIsChecked(!isChecked);
    };

    const responsive = {
        superLargeDesktop: {
            // the naming can be any, depends on you.
            breakpoint: { max: 4000, min: 3000 },
            items: 5
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    const subscription_price_list = [
        {
            id: 0,
            planeName: "Plane Name",
            price: 300
        },
        {
            id: 1,
            planeName: "Plane Name",
            price: 400
        }, {
            id: 2,
            planeName: "Plane Name",
            price: 900
        }, {
            id: 3,
            planeName: "Plane Name",
            price: 1000
        }, {
            id: 4,
            planeName: "Plane Name",
            price: 3300
        },
    ]


    const subscription_features_list = [
        {
            id: 0,
            text: "Lorem ipsum dolor sit amet"
        },
        {
            id: 1,
            text: "Lorem ipsum dolor sit amet"
        }, {
            id: 2,
            text: "Lorem ipsum dolor sit amet"
        }, {
            id: 3,
            text: "Lorem ipsum dolor sit amet"
        }, {
            id: 4,
            text: "Lorem ipsum dolor sit amet"
        },
    ]

    return (
        <>
            <PostLoginHeader />
            <div className="subscription-parent-section">

                ⁡⁢⁢⁢{/* background shapes style start */}⁡
                <div className="background-shapes-container">
                    <img className='blue_hexagon' src={blue_hexagon} alt="blue_hexagon" />
                    <img className='green_hexagon' src={green_hexagon} alt="green_hexagon" />
                    <img className='blue_gear' src={blue_gear} alt="blue_gear" />
                    <img className='green_gear' src={green_gear} alt="green_gear" />
                    <img className='green_rectangle' src={green_rectangle} alt="green_rectangle" />
                    <img className='blue_square' src={blue_square} alt="blue_square" />
                    <img className='green_circle' src={green_circle} alt="green_circle" />
                    <img className='blue_circle' src={blue_circle} alt="blue_circle" />
                </div>
                ⁡⁢⁢⁢{/* background shapes style end */}⁡

                <div className="subscription-content-parent">
                    <div className="header_text_wraper">
                        <h1>Product 1</h1>
                    </div>
                    <div className="subscription-para-wrapper">
                        <p>Lorem ipsum dolor sit amet consectetur adipiscing elit. Lorem ipsum dolor sit amet consectetur adipiscing elit.
                            Lorem ipsum dolor sit amet consectetur adipiscing elit. Lorem ipsum dolor sit amet consectetur adipiscing elit.
                            Lorem ipsum dolor sit amet consectetur adipiscing elit. Lorem ipsum dolor sit amet consectetur adipiscing elit.
                            Donec</p>
                    </div>
                    <div className="subscription-para-secondary-wrapper">
                        <p>Save up to 10% when you pay annually</p>
                    </div>
                    <div className="subscription-para-ternary-wrapper">
                        <p>Pay Monthly</p>
                        <div className={`switch ${isChecked ? 'switch-on' : 'switch-off'}`} onClick={handleToggle}>
                            <div className="switch-thumb"></div>
                        </div>
                        <p>Pay Anually</p>
                    </div>
                    <div className="Slider-parent-container">
                        <Carousel
                            swipeable={true}
                            draggable={true}
                            showDots={true}
                            responsive={responsive}
                            ssr={true} // means to render carousel on server-side.
                            infinite={false}
                            // autoPlay={this.props.deviceType !== "mobile" ? true : false}
                            // autoPlaySpeed={1000}
                            keyBoardControl={true}
                            customTransition="all .5"
                            transitionDuration={500}
                            containerClass="carousel-container"
                            removeArrowOnDeviceType={["tablet", "mobile"]}
                            // deviceType={this.props.deviceType}
                            dotListClass="custom-dot-list-style"

                        // itemClass="carousel-item-padding-40-px"
                        >
                            {/* <div>Item 1</div>
                            <div>Item 2</div>
                            <div>Item 3</div>
                            <div>Item 4</div> */}
                            {
                                subscription_price_list.map((value) => (

                                    <div className="carousal-individual-parent-container" key={value.id}>
                                        <div className="carsousal-header-wrapper">
                                            <h1>{value.planeName}</h1>
                                            <h1>₹ {value.price}<span className='month-span'>/month</span></h1>
                                        </div>
                                        {subscription_features_list.map((item) => (
                                            <div className="carousal-content-wrapper" key={item.id}>
                                                <div
                                                    className={`tick_wrong_container ${item.id <= 2 ? 'tick-green' : 'tick-red'}`}
                                                >
                                                    {item.id <= 2 ? '✔' : '✘'}
                                                </div>
                                                <div className="subscription_features_list">
                                                    <h4>{item.text}</h4>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                ))
                            }
                        </Carousel>
                    </div>
                </div>
            </div>
        </>
    )
}

export default SubscriptionPage