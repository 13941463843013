import React, { useContext } from 'react';
import './AttendancePrimaryTableStyle.scss';
import { CounterContext } from '../../App';

const AttendancePrimaryTable = ({ Data, attendanceDate, pageSize, setPageSize, currentPage, setCurrentPage, divisionCount, triggerGetDivisonWiseApi, searchedData }) => {
    const { handleChangeCount, handleDivisionWiseValue,
        handleselecteDivisonDate, setattendanceDivSelectedLabel, handleTotalStudentsCount, setattendanceStandardSelectedLabel, setattendanceMediumSelectedLabel, setattendanceInstituteselectedLabel } = useContext(CounterContext);
    const currentStudents = Data;
    const indexOfLastStudent = currentPage * pageSize;
    const indexOfFirstStudent = indexOfLastStudent - pageSize;
    const numberOfPages = Math.ceil(divisionCount / pageSize);
    const handleViewDocuments = (documents) => {
        setattendanceStandardSelectedLabel(documents.standardName);
        setattendanceInstituteselectedLabel(documents.instituteName);
        setattendanceMediumSelectedLabel(documents.mediumName);
        handleChangeCount(1);
        handleDivisionWiseValue(documents.divisionId);
        handleselecteDivisonDate(attendanceDate);
        setattendanceDivSelectedLabel(documents.divisionName);
        handleTotalStudentsCount(documents.totalStudents);
    };


    const goToPage = (page) => {
        if (page >= 1 && page <= numberOfPages) {
            setCurrentPage(page);
            if (page == 1) {
                triggerGetDivisonWiseApi(false, '1', pageSize);
            } else {
                triggerGetDivisonWiseApi(false, ((pageSize * (page - 1)) + 1).toString(), pageSize);
            }
        }
    };

    const handlePageSizeChange = (e) => {
        let size = parseInt(e.target.value);
        if (!isNaN(size) && size >= 1) {
            if (size > 25) {
                size = 25;
                setPageSize(25);
            } else {
                setPageSize(size);
            }
            setCurrentPage(1); // Reset to the first page when page size changes
            if (window.dbPageSize) {
                clearTimeout(window.dbPageSize);
            }
            window.dbPageSize = setTimeout(() => {
                triggerGetDivisonWiseApi(false, '1', size);
            }, 1500);
        }
    };

    const handleJumpToPageChange = (e) => {
        let page = parseInt(e.target.value);
        if (!isNaN(page)) {
            if (page > numberOfPages) {
                page = numberOfPages;
            }
            setCurrentPage(page);
            if (window.dbJumpPage) {
                clearTimeout(window.dbJumpPage);
            }
            window.dbJumpPage = setTimeout(() => {
                triggerGetDivisonWiseApi(false, (page * pageSize) - pageSize + 1, pageSize);
            }, 1500);
        }
    }

    const generatePage = (i) => {
        return <div key={i} onClick={() => goToPage(i + 1)} className={currentPage === i + 1 ? 'current_page_active' : 'numbers_contianer'}>
            {i + 1}
        </div>
    }

    const renderPages = () => {
        const arr = [];
        if (numberOfPages <= 4) {
            for (let i = 0; i < numberOfPages; i++) {
                arr.push(generatePage(i));
            }
        } else {
            for (let i = currentPage - 2; i < currentPage + 1 && currentPage > 0 && currentPage < numberOfPages; i++) {
                arr[i] = generatePage(i);
            }
            for (let i = 0; i < numberOfPages; i++) {
                if ((i == 0 || i == 1 || i == numberOfPages - 2 || i == numberOfPages - 1) && !arr[i]) {
                    arr[i] = generatePage(i);
                } else if (i == 2 && !arr[i]) {
                    arr[i] = <p>...</p>;
                } else if (i == numberOfPages - 3 && !arr[i]) {
                    arr[i] = <p>...</p>;
                }
            }
        }
        return arr;
    }

    return (
        <div className="admission-primary-table-container">
            {

                Data.length > 0 ?
                    <>
                        <table className="admission-primary-table">
                            <thead>
                                <tr>
                                    <th>Sr. No.</th>
                                    <th>Institute</th>
                                    <th>Board</th>
                                    <th>Std</th>
                                    <th>Div</th>
                                    <th>Medium</th>
                                    <th>Total Student</th>
                                    <th>Present Student</th>
                                    <th>Absent Student</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    currentStudents.map((item, index) => {
                                        let isAttendenceAdded = false;
                                        console.log(item.presentStudents, item.absentStudents, typeof item.presentStudents, typeof item.absentStudents);
                                        if (item.presentStudents || item.absentStudents) {
                                            isAttendenceAdded = true;
                                        }

                                        return <tr key={index} >
                                            <td>{index + 1}</td>
                                            <td>{item.instituteName}</td>
                                            <td>{item.boardName.split(" ")[0] + (" ") + item.boardName.split(" ")[1] + ("....")}</td>
                                            <td>{item.standardName}</td>
                                            <td>{item.divisionName}</td>
                                            <td>{item.mediumName}</td>
                                            <td>{item.totalStudents}</td>
                                            <td>{isAttendenceAdded ? item.presentStudents : "-"}</td>
                                            <td>{isAttendenceAdded ? item.absentStudents : "-"}</td>
                                            <td>
                                                <a className={`attendance-primary-link-text`} onClick={() => handleViewDocuments(item)}>
                                                    {isAttendenceAdded ? "View" : "Add"}
                                                </a>
                                            </td>
                                        </tr>
                                    })
                                }
                            </tbody>
                        </table>

                        <div className="pagination">
                            <div className="pagination_container">
                                <p>Showing {indexOfFirstStudent + 1} to {Math.min(indexOfFirstStudent + pageSize, divisionCount)} of {divisionCount} results</p>
                            </div>
                            <div className="pagination_controls">
                                <div className='display_container'>
                                    <p>Jump To Page</p>
                                    <input
                                        type="number"
                                        value={currentPage}
                                        onChange={handleJumpToPageChange}
                                        min="1"
                                        max={numberOfPages}
                                        placeholder="Page Size"
                                        className='extra_space'
                                    />
                                </div>
                                <div className='display_container'>
                                    <p>Display</p>
                                    <input
                                        type="number"
                                        value={pageSize}
                                        onChange={handlePageSizeChange}
                                        min="1"
                                        max={"25"}
                                        placeholder="Page Size"
                                        className='extra_space'
                                    />
                                </div>
                                <div className="arrows_container">

                                    <div onClick={() => goToPage(currentPage - 1)} className={currentPage > 1 ? 'left_arrow_active extra_space' : 'left_arrow_btn_container extra_space'}>
                                        <p>{"<"}</p>
                                    </div>
                                    {/* <button onClick={() => goToPage(currentPage - 1)} disabled={currentPage === 1}>{'<'}</button> */}
                                    <div className='number_pages_parent'>
                                        {renderPages()}
                                    </div>
                                    <div className={currentPage != numberOfPages ? 'right_arrow_active' : 'right_arrow_btn_container'} onClick={() => goToPage(currentPage + 1)} >
                                        <p>{">"}</p>
                                    </div>
                                    {/* <button onClick={() => goToPage(currentPage + 1)} disabled={currentPage === numberOfPages}>{'>'}</button> */}
                                </div>

                            </div>
                        </div>
                    </>
                    :
                    < div className="no_records_container">
                        <h3>No records found, Kindly reset your filter.</h3>
                    </div>
            }

        </div >
    );
};

export default AttendancePrimaryTable;