import React, { useState } from 'react';
import './PostLoginHeader.scss';
import PrimaryButton from '../PrimaryButton';
import { useNavigate } from 'react-router-dom';
import Logo from '../../../Assets/Images/AlphacubesLogo.svg';
import { FaUserCircle, FaBell, FaHandPointRight, FaUnlock } from "react-icons/fa";
import NotificationDetails from '../NotificationDetails/NotificationDetails';
import ProfilePopup from '../ProfilePopup/ProfilePopup';

const PostLoginHeader = (props) => {

    const navigate = useNavigate();

    const [displayMobileMenu, setDisplayMobileMenu] = useState(false);

    const handleHamburgerClick = () => {
        setDisplayMobileMenu(true);
        document.body.classList.add('overflow-hide');
    }

    const handleMobileMenuCloseClick = () => {
        setDisplayMobileMenu(false);
        document.body.classList.remove('overflow-hide');
    }

    const [userName, setUserName] = useState("Alphacubes")
    const [userEmail, setUserEmail] = useState("alphacubes123@gmail.com")

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isProfileOpen, setIsProfileOpen] = useState(false);



    const handleNotificationClick = () => {
        return;
        if (isProfileOpen == true) {
            setIsProfileOpen(false);
            setIsModalOpen(true);
        }
        else if (isModalOpen == false) {
            setIsModalOpen(true);
        } else {
            setIsModalOpen(false);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleProfileClick = () => {
        if (isModalOpen == true) {
            setIsModalOpen(false);
            setIsProfileOpen(true);
        }
        if (isProfileOpen == false) {
            setIsProfileOpen(true);
        } else {
            setIsProfileOpen(false);
        }
    }

    const handleProfileClose = () => {
        setIsProfileOpen(false);
    }


    return (
        <header className={props.isLinearGradientVisible ? 'post_login_header_container_linear_gradient' : 'post_login_header_container'}>
            <div className={`post-header-logo-container`}>
                <img className={`post-header-logo`} src={Logo} />
            </div>

            <div className={props.isLinearGradientVisible ? `post_logout_button_container_linear_gradient` : `post_logout_button_container`}>

            </div>
            <div onClick={handleNotificationClick} className={'notification-icon-container'}>
                <FaBell
                />
            </div>
            <div onClick={handleProfileClick} className={'profile-icon-container'}>
                <FaUserCircle />
            </div>
            <div onClick={handleHamburgerClick} className={`user-mobile-icon-container`}>
                <FaUserCircle />
            </div>
            <div className={`header-mobile-menu-container ${displayMobileMenu ? 'active' : ''}`} >
                <div className='post-header-main-container'>
                    <div className='post-header-content'>
                        <div className='post-header-upper-container'>
                            <div className={`post-mobile-header-close-container`}>
                                <p onClick={handleMobileMenuCloseClick}>X</p>
                            </div>
                            <div className='user-main-icon'>
                                <FaUserCircle />
                            </div>
                            <div className='user-detail-container'>
                                <div className='user-name-container'>
                                    {userName}
                                </div>
                                <div className='user-email-container'>
                                    {userEmail}
                                </div>
                            </div>

                            <div className='edit-user-icon'>
                                <FaUserCircle />
                                <p>
                                    Edit Profile
                                </p>
                            </div>
                            <div className='subscription-icon-container'>
                                <FaHandPointRight />
                                <p>
                                    Subscription
                                </p>
                            </div>
                            <div className='change-password-icon-container'>
                                <FaUnlock />
                                <p>
                                    Change Password
                                </p>
                            </div>
                        </div>
                        <div className='post-login-bottom-option'>
                            <div className={`post_mobile_logout_container`}>
                                <a className='link-text'>Help Center | </a>
                                <a
                                    onClick={() => {
                                        window.scrollTo({
                                            top: 0,
                                            behavior: 'smooth' // Smooth scrolling animation
                                        });
                                        sessionStorage.clear();
                                        localStorage.clear();
                                        navigate('/');

                                    }} className='link-text'> Logout</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <div className="modal-overlaycontent_notification" >
                    <div className="modal-content_notification" >
                        {/* <span className="close" onClick={handleCloseModal}>&times;</span> */}
                        <NotificationDetails onClose={handleCloseModal} />
                    </div>
                </div>
            )}

            {isProfileOpen && (
                <div className="modal-overlaycontent_profile" >
                    <div className="modal-content_profile">
                        {/* <span className="close" onClick={handleCloseModal}>&times;</span> */}
                        <ProfilePopup onClose={handleProfileClose} />
                    </div>
                </div>
            )}

        </header>
    )
}

export default PostLoginHeader;