// import React from "react";
// import './App.css';
// import './Utils/Styles/Common.scss';
// import { Route, Routes } from 'react-router-dom';
// import LandingPage from "./Pages/General/LandingPage";
// import RegistrationPage from "./Pages/Registration/RegistrationPage";
// import UserDashboard from "./Pages/Dashboard/UserDashboard";
// import Admission from "./Pages/Admission";
// import AdmissionTabsSection from "./Pages/AdmissionTabsSection";
// import AdmissionForm from "./Pages/General/AdmissionForm";
// import FeesReceipt from "./Pages/FeesReceipt";
// import FeesReceiptInstallment from "./Pages/FeesReceiptInstallment";
// import AttendanceModule from "./Pages/Attendance/AttendanceModule";
// import SubscriptionPage from "./Pages/Subscription";


// function App() {
//   return (
//     <div className="app">
//       <Routes>
//         {/* <Route path="/" element={<SubscriptionPage />} /> */}
//         <Route path="/" element={<LandingPage />} />
//         <Route path="/registration" element={<RegistrationPage />} />
//         <Route path="/dashboard" element={<UserDashboard />} />
//         <Route path="/admission" element={<Admission />} />
//         <Route path="/AdmissionTabsSection" element={<AdmissionTabsSection />} />
//         <Route path="/admissionform" element={<AdmissionForm />} />
//         <Route path="/feesReceipt" element={<FeesReceipt />} />
//         <Route path="/feesReceiptInstallment" element={<FeesReceiptInstallment />} />
//         <Route path="/attendanceModule" element={<AttendanceModule />} />

//         {/* <Route path="/subscriptionPage" element={<SubscriptionPage />} /> */}
//       </Routes>
//     </div>
//   )
// }

// export default App;
import React, { useState, useCallback } from "react";
import './App.css';
import './Utils/Styles/Common.scss';
import { Route, Routes } from 'react-router-dom';
import LandingPage from "./Pages/General/LandingPage";
import RegistrationPage from "./Pages/Registration/RegistrationPage";
import UserDashboard from "./Pages/Dashboard/UserDashboard";
import Admission from "./Pages/Admission";
import AdmissionTabsSection from "./Pages/AdmissionTabsSection";
import AdmissionForm from "./Pages/General/AdmissionForm";
import FeesReceipt from "./Pages/FeesReceipt";
import FeesReceiptInstallment from "./Pages/FeesReceiptInstallment";
import AttendanceModule from "./Pages/Attendance/AttendanceModule";
import SubscriptionPage from "./Pages/Subscription";

const CounterContext = React.createContext();

function App() {
  const [numberOfCount, setnumberOfCount] = useState(0);
  const [divisionWiseId, setdivisionWiseId] = useState(0);
  const [selecteDivisonDate, setSelecteDivisonDate] = useState('');
  const [attendanceInstituteselectedValue, setattendanceInstituteSelectedValue] = useState('');
  const [attendanceBoardSelectedValue, setattendanceBoardSelectedValue] = useState('');
  const [attendanceMediumSelectedValue, setattendanceMediumSelectedValue] = useState('');
  const [attendanceStandardSelectedValue, setattendanceStandardSelectedValue] = useState('');
  const [attendanceInstituteselectedLabel, setattendanceInstituteselectedLabel] = useState('');
  const [attendanceDivSelectedLabel, setattendanceDivSelectedLabel] = useState('');
  const [attendanceMediumSelectedLabel, setattendanceMediumSelectedLabel] = useState('');
  const [attendanceStandardSelectedLabel, setattendanceStandardSelectedLabel] = useState('');
  const [totatStudentsCount, settotatStudentsCount] = useState(0);
  const [studentName, setstudentName] = useState('');
  const [rollNo, setrollNo] = useState(0);
  const [userID, setuserID] = useState(0);

  const handleChangeCount = useCallback(
    (item) => setnumberOfCount(item),
    [setnumberOfCount]
  );

  const handleDivisionWiseValue = useCallback(
    (item) => setdivisionWiseId(item),
    [setdivisionWiseId]
  );

  const handleselecteDivisonDate = useCallback(
    (item) => setSelecteDivisonDate(item),
    [setSelecteDivisonDate]
  );

  const handleTotalStudentsCount = useCallback(
    (item) => settotatStudentsCount(item),
    [settotatStudentsCount]
  );

  const handleStudentName = useCallback(
    (item) => setstudentName(item),
    [setstudentName]
  );

  const handleStudentRollNo = useCallback(
    (item) => setrollNo(item),
    [setrollNo]
  );

  const handleStudentuserID = useCallback(
    (item) => setuserID(item),
    [setuserID]
  );

  const contextValue = {
    numberOfCount,
    handleChangeCount,
    divisionWiseId,
    handleDivisionWiseValue,
    selecteDivisonDate,
    handleselecteDivisonDate,
    attendanceInstituteselectedValue,
    setattendanceInstituteSelectedValue,
    attendanceBoardSelectedValue,
    setattendanceBoardSelectedValue,
    attendanceMediumSelectedValue,
    setattendanceMediumSelectedValue,
    attendanceStandardSelectedValue,
    setattendanceStandardSelectedValue,
    totatStudentsCount,
    handleTotalStudentsCount,
    studentName,
    rollNo,
    userID,
    handleStudentName,
    handleStudentRollNo,
    handleStudentuserID,
    attendanceStandardSelectedLabel,
    attendanceMediumSelectedLabel,
    attendanceDivSelectedLabel,
    attendanceInstituteselectedLabel,
    setattendanceStandardSelectedLabel,
    setattendanceMediumSelectedLabel,
    setattendanceDivSelectedLabel,
    setattendanceInstituteselectedLabel,
  };

  return (
    <CounterContext.Provider value={contextValue}>

      <div className="app">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/registration" element={<RegistrationPage />} />
          <Route path="/dashboard" element={<UserDashboard />} />
          <Route path="/admission" element={<Admission />} />
          <Route path="/AdmissionTabsSection" element={<AdmissionTabsSection />} />
          <Route path="/admissionform" element={<AdmissionForm />} />
          <Route path="/feesReceipt" element={<FeesReceipt />} />
          <Route path="/feesReceiptInstallment" element={<FeesReceiptInstallment />} />
          <Route path="/attendanceModule" element={<AttendanceModule />} />
        </Routes>
      </div>
    </CounterContext.Provider>
  );
}

export { CounterContext };
export default App;
