import Config from "../../Config/Config";
import axios from "axios";

const ApiCall = (endpoint, body, method = 'post', token) => {

    const data = JSON.stringify(body);

    let myToken = sessionStorage.getItem("accessToken");
    if (!myToken) {
        myToken = localStorage.getItem("accessToken");
    }

    const authToken = token ? token : myToken;

    return new Promise(async (res, rej) => {
        try {
            setTimeout(() => {
                let config = {
                    method: method.toLowerCase(), // Convert method to lowercase
                    maxBodyLength: Infinity,
                    url: Config.apiURL + endpoint,
                    headers:
                    {
                        'Content-Type': 'application/json',
                        'Authorization': 'Bearer ' + authToken
                    },
                    data: method.toLowerCase() === 'post' || method.toLocaleLowerCase() === 'put' ? data : undefined // Only add data for POST requests
                };

                axios.request(config)
                    .then((response) => {
                        res(response.data);
                    })
                    .catch((error) => {
                        if (error.response && error.response.data && (error.response.data.status === 401 || error.response.data.status === 400 || error.response.data.status === 500)) {
                            rej(error.response.data);
                        } else {
                            rej({
                                status: "unknown",
                                msg: "Something went wrong in axios, please try again",
                            });
                        }
                    });
            });
        } catch (error) {
            rej({
                status: "unknown",
                msg: "Something went wrong in promise, please try again no in here ",
            });
        }
    });
};

export { ApiCall };
