import React, { useEffect, useState } from 'react';
import './UserDashboard.scss';
import { useNavigate } from 'react-router-dom';
import Dashboard from '../../../Components/Dashboard';
import PreLoginHeader from '../../../Components/Common/PreLoginHeader';
import PostLoginHeader from '../../../Components/Common/PostLoginHeader';
import CommonFooter from '../../../Components/Common/CommonFooter';

const UserDashboard = () => {

    const navigate = useNavigate();
    const [displayLoginPopup, setDisplayLoginPopup] = useState(false);

    useEffect(() => {
        document.title = 'Dashboard';
        if (localStorage.getItem("accessToken") || sessionStorage.getItem("accessToken")) {
            console.log("here");
        } else {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // Smooth scrolling animation
            });
            navigate("/");
        }
    }, []);

    return (
        <div className={`user-dashboard-main-container`}>

            {/* <PreLoginHeader setDisplayLoginPopup={setDisplayLoginPopup} /> */}
            <PostLoginHeader isLinearGradientVisible={false} />
            <Dashboard />
            {/* <CommonFooter /> */}

        </div>
    )
}

export default UserDashboard;